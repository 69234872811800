$(document).ready(function() {
    //Variável para definir se é para buscar as lojas
    var blockGetStores = false;

    /**
	 * Recuperando informações do cep
	 */
	$("body").on("blur", "#cep_store", function () {
		if ($(this).is("input")) {
			var cep = $(this).val();

            if ($.trim(cep) !== "") {
                $("#loading").show();
                $.getJSON(URL_BASE+"adm/cep", {cep:cep} , function (data) {
                    //Atualiza estado
                    var $estado = $("select#estado");
                    $estado.val(data.uf);
                    $estado.trigger('change');
    
                    //Atualiza cidade
                    var $cities = $(".select-custom.cities select"),
                        $city = $(".select-custom.cities select#cidade_"+data.uf);
    
                    blockGetStores = true; //bloqueia a busca das lojas
                    $cities.each(function (index, element) {
                        $(this).val("").trigger('change');    
                    });
                    blockGetStores = false; //libera as buscas da loja
                    $city.val(data.city).trigger('change');
    
                    //Verifica se existe lojas para o CEP
                    var indexUF = $estado.find("option:selected").index(),
                        indexCity = $city.find("option:selected").index();
    
                    if (indexUF == 0 || indexCity == 0) {
                        alert("Não encontramos loja para CEP selecionado.");
                    }
                    
                    $("#loading").hide();
                });
            }
		}
    });

    /**
     * Mudar/Selecionar um estado
     */
    $("body").on("change", "select#estado", function () {
        var indexOpt = $(this).find("option:selected").index();
            indexOpt = (indexOpt < 0)? 0:indexOpt;

        var $liOpt = $(this).next().next().children("div").eq(indexOpt);
        $liOpt.trigger('click');

        //Limpar as seleções das cidades
        blockGetStores = true; //bloqueia a busca das lojas
        $(".select-custom.cities").addClass("d-none");
        var $cities = $(".select-custom.cities select");
        $cities.each(function (index, element) {
            $(this).val("").trigger('change');    
        });
        blockGetStores = false; //libera a busca das lojas
        $(".select-custom.cities[data-uf='" + $(this).val() + "']").removeClass("d-none");
    });
    
    /**
     * Mudar/Selecionar um cidade
     */
    $("body").on("change", ".select-custom.cities select", function () {
        var $uf = $("select#estado").val(),
            $select = $(this),
            indexOpt = $select.find("option:selected").index();
            indexOpt = (indexOpt < 0)? 0:indexOpt;

        var $liOpt = $(this).next().next().children("div").eq(indexOpt);
        $liOpt.trigger('click');

        //Verifica qual o item a ser filtrado
        if ($uf == $select.parent().data("uf")) {
            //verifica se a busca das lojas está liberada
            if (!blockGetStores) {
                blockGetStores = true;  //bloqueia a busca das lojas evitar ação duplicada
                $("#loading").show();
                $.post(URL_BASE+"onde-tem/stores", {uf:$uf, city:$select.val()} , function (data) {
                    blockGetStores = false; //libera a busca das lojas

                    $(".result-filter").html(data);

                    $("#loading").hide();
                });
            }
        }
    });

    $("body.ctl-contato").on("click", ".nav-tabs .nav-link", function () {
        window.location.hash = $(this).attr("href");

        if ($(this).attr("href") == "#sac") {
            $("header .navbar .navbar-collapse a").removeClass("ativo");
            $("header .navbar .navbar-collapse a.tab-contato").addClass("ativo");
        }else{
            $("header .navbar .navbar-collapse a.tab-contato").removeClass("ativo").parent().prev().find("a").addClass("ativo");
        }
    });

    $("body").on("click", "header .tab-contato", function () {
        var tab = $(this).attr("href");
        $(".nav-tabs a[href='"+tab+"']").click();
    });

    showHash();
    sizeYoutube();
    sizeCoverHome();
    sizeDuvidas();
    sizeBgMenu();
});

$(window).on('load', function () {
    showHash();
    sizeYoutube();
    sizeCoverHome();
    sizeDuvidas();
    sizeBgMenu();
});

$(window).on('resize', function () {
    sizeYoutube();
    sizeCoverHome();
    sizeDuvidas();
    sizeBgMenu();
});

/**
 * Show hash
 */
function showHash () {
    var x = location.hash;
    if (x) {
        // $(".nav-tabs a[href='"+x+"']").click();
        
        var body = $("html, body");
        var top = 0;
        var left = 0;
        if ($(x).length > 0) {
            var position = $(x).position();
                top = position.top;
                left = position.left;
        }
        
	    body.stop().animate({scrollTop:top}, '500', 'swing');

        // if (x=="#sac") {
            // $("header .navbar .navbar-collapse a").removeClass("ativo");
            // $("header .navbar .navbar-collapse a.tab-contato").addClass("ativo");
        // }
    }
}

/**
 * Size video Youtube
 */
function sizeYoutube () {
    $(".iframe-youtube").each(function (index, element) {
        var w = $(this).width();
        var h = (w * 315) / 560;
        $(".iframe-youtube").css("height", h+"px");
    });
}

/**
 * Size cover Home
 */
function sizeCoverHome () {

    if ($(".ctl-index.act-index .cover").length > 0) {
        var h = $(window).height();
        h = (h<650)? 650:h;

        $(".ctl-index.act-index .cover").height(h+"px");
    }

    // $(".iframe-youtube").each(function (index, element) {
    //     var w = $(this).width();
    //     var h = (w * 315) / 560;
    //     $(".iframe-youtube").css("height", h+"px");
    // });
}

/**
 * Altura da tela de dúvidas
 */
function sizeDuvidas() {
    if ($(".ctl-duvidas-frequentes.act-index .duvidas-frequentes").length > 0) {
        var minH = $(window).height() - $("footer").height();
        $(".duvidas-frequentes").css("min-height", minH+"px");
    }
}

/**
 * Altura do menu
 */
function sizeBgMenu() {
    var minH = $(window).height();
    $("header #menu").css("min-height", minH+"px");
}
