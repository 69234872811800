$(document).ready(function() {
    if ($(".ctl-orcamento.act-index").length > 0) {
        /**
         * Selecionar Sexo
         */
        $("body").on("click", ".orcamento .tab-content #sexo input[name='sexo']", function(){
            var sexo = $(this).val();

            $("#loading").show();
            $(".orcamento .tab-content #sexo img").removeClass("ativo");
            $(".orcamento .tab-content #sexo #"+sexo).next("img").addClass("ativo");

            $.getJSON(URL_BASE+"orcamento/change", {type:"sexo", sexo:sexo} , function (data) {
                var $breadCrumbSexo = $(".orcamento .tab-content .breadcrumb .breadcrumb-item.sexo a"),
                    $imgSexoRegiao = $(".img-sexo-regiao");
                
                //Alterar breadCrumb
                $breadCrumbSexo.html('<i class="fas fa-check-circle"></i> '+sexo.toUpperCase());

                //Alterar imagem
                if ($.trim(data.orcamento.regiao) !== "") {
                    $imgSexoRegiao.html('<img class="img-fluid mb-4" src="'+URL_BASE+'assets/img/orcamento/sexo-'+sexo+"-"+retirarCaracteresEspeciais(data.orcamento.regiao)+'.gif">');
                }else{
                    $imgSexoRegiao.html('<img class="img-fluid mb-4" src="'+URL_BASE+'assets/img/orcamento/sexo-'+sexo+'.jpg">');
                }

                
                $('#regiao-tab').tab('show');
                $("#loading").hide();
            });
        });

        /**
         * Selecionar regiao
         */
        $("body").on("change", ".orcamento .tab-content #regiao input[name='regiao']", function(){
            var regiao = $(this).val();

            $("#loading").show();
            $.getJSON(URL_BASE+"orcamento/change", {type:"regiao", regiao:regiao} , function (data) {
                var $next = $(".orcamento .tab-content #local"),
                    $breadCrumbRegiao = $(".orcamento .tab-content .breadcrumb .breadcrumb-item.regiao a"),
                    $breadCrumbLocal = $(".orcamento .tab-content .breadcrumb .breadcrumb-item.local a"),
                    $imgSexoRegiao = $(".img-sexo-regiao"),
                    $locais_list = $next.find(".locais-list");
                
                //Alterar breadCrumb
                $breadCrumbRegiao.html('<i class="fas fa-check-circle"></i> '+regiao.toUpperCase());
                $breadCrumbLocal.html('<i class="fas fa-check-circle"></i> ').parent().addClass("d-none");


                $locais = "";
                if (data.locais.length > 0) {
                    for (var index = 0; index < data.locais.length; index++) {
                        var local = data.locais[index];
                        $locais += '<label class="btn btn-secondary btn-border-white mb-2"><input type="radio" name="local" value="'+local+'" autocomplete="off"> '+local+' </label>';
                    }
                }

                //Alterar lista de locais
                $locais_list.html($locais);

                //Alterar imagem
                $imgSexoRegiao.html('<img class="img-fluid mb-4" src="'+URL_BASE+'assets/img/orcamento/sexo-'+data.orcamento.sexo+"-"+retirarCaracteresEspeciais(regiao)+'.gif">');

                // $('#local-tab').tab('show');
                $("#loading").hide();
            });
        });

        /**
         * Selecionar local
         */
        $("body").on("change", ".orcamento .tab-content #local input[name='local']", function(){
            var local = $(this).val();
            
            $("#loading").show();
            $.getJSON(URL_BASE+"orcamento/change", {type:"local", local:local} , function (data) {
                var $breadCrumbLocal = $(".orcamento .tab-content .breadcrumb .breadcrumb-item.local a");
                
                //Alterar breadCrumb
                $breadCrumbLocal.html('<i class="fas fa-check-circle"></i> '+local.toUpperCase()).parent().removeClass("d-none");

                $("#loading").hide();
            });
        });

        /**
         * Botão voltar
         */
        $("body.ctl-orcamento").on("click", ".btn-return", function(e) {
            e.preventDefault();

            $($(this).attr("href")+"-tab").tab('show');
        });

        /**
         * Botão avançar
         */
        $("body.ctl-orcamento").on("click", ".btn-advanced", function(e) {
            e.preventDefault();

            var tab = $(this).attr("href");

            if (tab == "#local") {
                var regiao = $(".orcamento .tab-content #regiao input[name='regiao']:checked").val();

                if (!regiao) {
                    alert("Selecione uma região do corpo para prosseguir.");
                    return false;
                }

                $(tab+"-tab").tab('show');
            }else if (tab == "#carrinho") {
                var $theForm = $(this).closest('form'),
                    $cor = $theForm.find("input[name='cor']:checked"),
                    $largura = $theForm.find("input[name='largura']"),
                    $altura = $theForm.find("input[name='altura']"),
                    $mensagem = $theForm.find("textarea[name='mensagem']");
                if (( typeof($theForm[0].checkValidity) == "function" ) && !$theForm[0].checkValidity()) {
                    if ($largura.hasClass("invalid") || $altura.hasClass("invalid") || $mensagem.hasClass("invalid")) {
                        alert("Preencher campos obrigátorios. [Largura, Altura e Informações adicionais]");
                        return false;
                    }
                }

                $("#loading").show();
                $.post(URL_BASE+"orcamento/carrinho", {type:"especificacoes", cor:$cor.val(), largura:$largura.val(), altura:$altura.val(), mensagem:$mensagem.val()} , function (data) {
                    $("#carrinho .cart_itens").html(data);

                    $(tab+"-tab").tab('show');
                    $("#loading").hide();
                });

            }else{
                $(tab+"-tab").tab('show');
            }


            
        });
    }

});